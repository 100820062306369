import React, { Component } from 'react'
//import { Link } from "gatsby"
import PropTypes from 'prop-types'
import { Flex, Box } from '@rebass/grid'
//import styled from "styled-components"
import styled from 'styled-components'
import { fontBold, theme } from '../util/style'
//import { DefaultPlayer as Video } from 'react-html5video'

import ReactPlayer from 'react-player'

//import 'react-html5video/dist/styles.css'

class PageVideo extends Component {
  render() {
    const { src } = this.props

    return (
      <Wrap>
        <Flex>
          <Box width={1} px={2}>
            <VideoWrap>
              <ReactPlayer
                className="react-player"
                url={src}
                width="100%"
                height="100%"
              />
            </VideoWrap>
          </Box>
        </Flex>
      </Wrap>
    )
  }

  /*
    const { src, title, description } = this.props;

    let captionContent, titleContent, descriptionContent;
    if (title || description) {
      if (title) {
        titleContent = <Title dangerouslySetInnerHTML={{ __html: title }} />;
      }
      if (description) {
        descriptionContent = (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        );
      }
      captionContent = (
        <Flex>
          <Box width={1} px={2}>
            <Caption>
              {titleContent}
              {descriptionContent}
            </Caption>
          </Box>
        </Flex>
      );
    }

    return (
      <Wrap>
        <Flex>
          <Box width={1} px={2}>
            <VideoWrap>
              <VideoInner>
                <Video
                  autoPlay
                  loop
                  muted
                  controls={[
                    "PlayPause",
                    "Seek",
                    "Time",
                    "Volume",
                    "Fullscreen"
                  ]}
                  poster=""
                  onCanPlayThrough={() => {
                    // Do stuff
                  }}
                >
                  <source src={src} type="video/mp4" />
                </Video>
              </VideoInner>
            </VideoWrap>
          </Box>
        </Flex>
        {captionContent}
      </Wrap>
    );
  }
  */
}

PageVideo.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default PageVideo

const Wrap = styled.div`
  margin-bottom: 85px;
`

const VideoWrap = styled.div`
  background: #eee;
  width: 100%;
  padding-top: ${(9 / 16) * 100}%;
  position: relative;
  margin-bottom: 20px;
  .react-player {
    position: absolute;
    left: 0;
    top: 0;
  }
`

/*
const VideoWrap = styled.div`
  background: #eee;
  width: 100%;
  padding-top: ${(9 / 16) * 100}%;
  position: relative;
  margin-bottom: 20px;
`;

const VideoInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  .rh5v-Overlay_inner {
    border-radius: 30px;
  }
  .rh5v-DefaultPlayer_video {
    display: block;
  }
  .rh5v-Volume_icon {
    padding: 2px;
  }
  .rh5v-PlayPause_icon {
    padding: 2px;
  }
  div {
    font-size: 13px;
  }
`;

const Caption = styled.div``;

const Title = styled.div`
  color: ${theme.colors.grey2};
  p {
    margin-top: 0px;
    margin-bottom: 1em;
  }
  strong,
  b {
    ${fontBold()};
  }
`;

const Description = styled.div`
  width: 80%;
  color: ${theme.colors.grey1};
  a {
    color: ${theme.colors.grey1};
  }
`;
*/
